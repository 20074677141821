<template>
  <v-container grid-list-s text-xs-center class="pt-16">
    <v-layout row wrap>
      <v-col md="6" offset-md="3" align="center">
        <h4 class="display-2 font-weight-bold primary--text">
          {{ $t('login') }}
        </h4>
        <br />
        <v-form
          ref="form"
          lazy-validation
          class="login"
          @submit.prevent="loginUser"
        >
          <v-text-field
            v-model="email"
            outlined
            clearable
            type="email"
            placeholder="email@example.com"
            :label="$t('email')"
            :rules="[emailRules]"
            prepend-icon="mdi-email"
            color="primary"
            required
            @input="resetErrors"
          />
          <v-text-field
            v-model="password"
            :rules="[v => !!v || $t('passwordValidation')]"
            outlined
            clearable
            :label="$t('password')"
            prepend-icon="mdi-lock"
            type="password"
            required
            placeholder="Password"
            @input="resetErrors"
          />

          <div class=" float-right">
            <router-link :to="{ name: 'ForgotPassword' }"
              >Forgot Password?</router-link
            >
          </div>
          <br />
          <v-row v-if="errors" class="error--text" justify="center">{{
            errors
          }}</v-row>
          <v-btn color="primary" large type="submit" class="text-capitalize ">
            {{ $t('loginButton') }}
          </v-btn>
        </v-form>
      </v-col>
    </v-layout>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  data: () => ({
    email: '',
    password: '',
    errors: '',
    emailErrors: ''
  }),
  computed: {
    ...mapGetters(['authStatus', 'authStatusEnum'])
  },
  methods: {
    ...mapActions(['login', 'getUserStatus']),
    resetErrors() {
      this.errors = ''
      this.emailErrors = ''
    },
    emailRules(value) {
      if (!value) {
        return this.$t('emailValidationRequired')
      } else {
        if (!/.+@.+\..+/.test(value)) {
          return this.$t('emailValidation')
        }
        if (this.emailErrors) return this.emailErrors
        else return true
      }
    },
    loginUser() {
      if (this.$refs.form.validate()) {
        const email = this.email
        const password = this.password
        this.login({ email, password })
          .then(res => {
            if (res.status !== 200) {
              if (res.data.non_field_errors) {
                this.errors = res.data.non_field_errors[0]
              }
              if (res.data.email) this.emailErrors = res.data.email[0]
              this.$refs.form.validate()
            } else {
              this.getUserStatus().then(() => {
                if (this.authStatus === this.authStatusEnum.ACTIVE)
                  this.$router.push({ name: 'MyConsultations' })
                else this.$router.push({ name: 'AccountStatus' })
              })
            }
          })
          .catch(err => console.log(err))
      }
    }
  }
})
</script>
